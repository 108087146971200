<template>
  <div class="wrap">
    <div class="The_header">
      <ul class="bg-fff">
        <li class="left">
          <router-link to>
            <i class="iconfont icon-fanhui" @click="goHome"></i>
          </router-link>
        </li>
        <li>{{ t("common.rechargeWay.PayOnline") }}</li>
      </ul>
    </div>
    <div class="main main_page" v-if="rechargeHtmlData.type === 'SHOW_RESULT'">
      <div class="Pay_usdt_box">
          
          <div class="Beneficiary">
            <p>
              <b>{{ t("common.title.Monto") }}:</b>
              <span>{{ rechargeHtmlData.money }}</span>
              <button type="button" @click.prevent="doCopy(rechargeHtmlData.money)">
                {{ t("common.button.copy") }}
              </button>
            </p>

            <p>
              <b>{{ t("common.title.Nombre") }}:</b>
              <span>STP</span>
              <button type="button" @click.prevent="doCopy('STP')">
                {{ t("common.button.copy") }}
              </button>
            </p>
            
            <p>
              <b>{{ t("common.title.Calbe") }}:</b>
              <span>{{ rechargeHtml }}</span>
              <button
                type="button"
                @click.prevent="doCopy(rechargeHtml)"
              >
                {{ t("common.button.copy") }}
              </button>
            </p>

            
          </div>
        </div>
    </div>
    <div class="main main_page" v-else v-html="rechargeHtml"></div>
  </div>
</template>
<script>
import { message } from "ant-design-vue";
import { defineComponent } from "vue";
import { rechargeHtml } from "@/hooks/rechargeHtml";
import { rechargeHtmlData } from "@/hooks/rechargeHtmlData";
import { useRouter } from "vue-router";
import { useI18n } from "@/lang";
import { copyText } from "vue3-clipboard";
export default defineComponent({
  setup() {
    const router = useRouter();
    const { t } = useI18n() 
    const goHome = async () => {
      rechargeHtml.value = "";
      await router.replace("/home");
    };

    function doCopy(text) {
      copyText(text, undefined, (error, event) => {
        console.log(event);
        if (!error) {
          message.success({
            key: "copy",
            content: t("common.clipboard.success"),
          });
        }
      });
    }

    return {
      rechargeHtml,
      rechargeHtmlData,
      goHome,
      doCopy,
      t
    };
  },
});
</script>